.App {
  font-family: 'Monserrat';
  position: relative;
  overflow-x: hidden;
  /* overflow-y: scroll;
  scroll-snap-type: y mandatory; 
  scroll-behavior: smooth; */
  height: 100vh;
}

