.contacts {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
    /* scroll-snap-align: start; */
}

.contacts__container {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.contacts__title {
    font-size: 30px;
    font-weight: 600;
    line-height: 150%;
}

.contacts__info {
    font-size: 18px;
}

.contacts__mail {
    margin-left: 10px;
}

.contacts__link {
    text-decoration: none;
}

.contacts__link:hover {
    text-decoration: underline;
}

.contacts__map {
    width: 560px;
    height: 400px;
}

@media screen and (max-width: 1270px) {
    .contacts__container {
        width: 90%;
    }
    .contacts__map {
        width: 480px;
        height: 320px;
    }
}

@media screen and (max-width: 1100px) {
    .contacts__map {
        width: 380px;
        height: 280px;
    }
}

@media screen and (max-width: 900px) {
    .contacts__map {
        width: 300px;
        height: 230px;
    }
}

@media screen and (max-width: 890px) {
    .contacts__title {
        font-size: 25px;
    }
    
}

@media screen and (max-width: 800px) {
    .contacts {
       padding-top: 50px;
    }
    .contacts__container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .contacts__left {
        margin-bottom: 40px;
    }
}