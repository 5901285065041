.products {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    /* margin-bottom: 130px; */
    /* scroll-snap-align: start; */
    padding-top: 100px;
}

.products__container {
    width: 80%;
}

.products__title {
    font-size: 30px;
    font-weight: 600;
    line-height: 150%;
}

.products__desc {
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 60px;
}

.products__img {
    width: 250px;
    height: 230px;
}

.products__items {
    display: flex;
    justify-content: space-between;
}

.products__item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.products__item_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid rgb(29, 105, 55, 0.1);
    border-radius: 10%;
    padding: 20px;
    margin-bottom: 20px;
}

.products__name {
    font-size: 15px;
    font-weight: 600;
    line-height: 150%;
    margin-top: 20px;
}

.products__btn {
    cursor: pointer;
    border: 1px solid transparent;
    background-color: #1d6937;
    color: #fff;
    padding: 10px;
    border-radius: 20px;
    width: 200px;
    font-size: 18px;
    font-weight: 600;
    transition: 0.5s ease;
}

.products__btn:hover {
    border: 1px solid #1d6937;
    color: #1d6937;
    background-color: transparent;
}

@media screen and (max-width: 1240px) {
    .products__container {
        width: 90%;
    }
}

@media screen and (max-width: 1090px) {
    .products__img {
        width: 220px;
        height: 200px;
    }
}

@media screen and (max-width: 960px) {
    .products__img {
        width: 180px;
        height: 160px;
    }
}

@media screen and (max-width: 890px) {
    .products__title {
        font-size: 25px;
    }
    
    .products__desc {
        font-size: 22px;
    }
}

@media screen and (max-width: 800px) {
    .products {
        height: 300vh;
    }
    .products__title {
        text-align: center;
    }
    
    .products__desc {
        text-align: center;
    }

    .products__items {
       flex-direction: column;
       align-items: center;
    }

    .products__item { 
        margin-bottom: 10px;
        height: 80vh;
    }

    .products__img {
        width: 250px;
        height: 250px;
    }
}
