@font-face {
    font-family: 'Monserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./Montserrat-Regular.ttf') format('TrueType');
  }
  @font-face {
    font-family: 'Monserrat semi-bold';
    font-style: bold;
    font-weight: 600;
    font-display: swap;
    src: url('./Montserrat-SemiBold.ttf') format('TrueType');
  }