.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 11;
    display: none;
}

.show {
    display: flex;
}

/* .modal__wrapper {
    position: relative;
    width: 100%;
    height: 100vh;

} */

.modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    opacity: 0.8;
}


.modal__container {
    position: fixed;
    top: 15%;
    left: 10%;
    width: 80%;
    height: 70%;
    background-color: #fff;
    border-radius: 30px;
    padding-left: 3%;
    padding-right: 3%;
    opacity: 1;
}

.modal__title {
    font-size: 30px;
    font-weight: 600;
    margin: 20px 0 0 0;
    /* line-height: 100%; */
    color: #1d6937;
}

.modal__line {
    width: 25%;
    height: 3px;
    background-color: #1d6937;
    margin-top: 20px;
    margin-bottom: 40px;
}

.modal__form_top {
    display: flex;
    /* justify-content: space-between; */
    gap: 100px;
    margin-bottom: 40px;
}

.modal__form_bottom {
    display: flex;
    /* justify-content: space-between; */
    gap: 100px;
    margin-bottom: 60px;
    width: 100%;
}

.modal__form_item {
    display: flex;
    flex-direction: column;
}

.modal__form_label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.modal__form_input {
    box-sizing: border-box;
    border: 2px solid rgb(29, 105, 55, 0.3);
    border-radius: 10px;
    width: 250px;
    height: 50px;
    padding: 0 0 0 5px;
    color: #1d6937;
    outline: none;
    transition: 0.5s ease;
}

.modal__form_input:focus {
    border: 2px solid #1d6937;
}

.modal__form_input::placeholder {
    color: rgb(29, 105, 55, 0.7);
}
.modal__form_placeholder, .modal__form_select {
    color: rgb(29, 105, 55, 0.7);
}

/* .modal__form_select {
   height: 50px;
} */

.modal__form_big {
    width: 600px;
}


.modal__form_area {
    width: 100%;
    height: 50px;
    /* border-radius: 100px; */
    resize: none;
    padding-top: 15px;
}

.modal__form_btn {
    cursor: pointer;
    border: 1px solid transparent;
    color: #fff;
    background-color: #1d6937;
    padding: 10px;
    border-radius: 20px;
    width: 170px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.5s ease;
}

.modal__form_btn:hover {
    border: 1px solid #1d6937;
    color: #1d6937;
    background-color: transparent;
}

@media screen and (max-width: 1720px) {
    .modal__form_top {
        gap: 200px;
    }
    
    .modal__form_bottom {
        gap: 200px;
    }

    .modal__form_big {
        width: 700px;
    }
}

@media screen and (max-width: 1460px){
    .modal__form_top {
        gap: 150px;
    }
    
    .modal__form_bottom {
        gap: 150px;
    }

    .modal__form_big {
        width: 650px;
    }
}

@media screen and (max-width: 1310px){
    .modal__form_top {
        gap: 100px;
    }
    
    .modal__form_bottom {
        gap: 100px;
    }

    .modal__form_big {
        width: 600px;
    }
}

@media screen and (max-width: 1190px){
    .modal__form_top {
        gap: 60px;
    }
    
    .modal__form_bottom {
        gap: 60px;
    }

    .modal__form_big {
        width: 560px;
    }
}

@media screen and (max-width: 1085px){
    .modal__form_top {
        gap: 40px;
    }
    
    .modal__form_bottom {
        gap: 40px;
    }

    .modal__form_big {
        width: 540px;
    }
}


@media screen and (max-width: 1030px) {
    .modal__form_input { 
        width: 225px;
        font-size: 15px;
    }

    .modal__form_area {
        width: 100%;
    }
    .modal__form_big {
        width: 490px;
    }
}

@media screen and (max-width: 960px) {
    .modal__form_top {
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .modal__form_bottom {
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }

    .modal__container {
        position: fixed;
        top: 2%;
        left: 10%;
        width: 80%;
        height: 95%;
    }

    .modal__form_input { 
        margin-bottom: 10px;
        height: 40px;
    }
    .modal__form_big {
        width: 225px;
    }
    
    .modal__form_area {
        padding-top: 10px;
    }

    .modal__title {
        text-align: center;
    }

    .modal__line {
        width: 40%;
        height: 3px;
        margin-top: 20px;
        margin-bottom: 30px;
        margin-left: 30%;
    }

    .modal__form_label {
        text-align: center;
    }

    .modal__form_btn { 
        margin-left: 37%;
    }

}

@media screen and (max-width: 643px) { 
    .modal__form_btn { 
        margin-left: 34%;
    }
}

@media screen and (max-width: 563px) { 
    .modal__form_btn { 
        margin-left: 30%;
    }
}

@media screen and (max-width: 430px) { 
    .modal__form_btn { 
        margin-left: 25%;
    }
}

@media screen and (max-width: 800px) and (max-height: 610px) {
    .modal__title {
        font-size: 25px;
        margin: 15px 0 0 0;
    }

    .modal__line {
        margin-top: 15px;
        margin-bottom: 10px;
    }

}

