.about {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    /* padding-bottom: 130px; */
    /* scroll-snap-align: start; */
    padding-top: 80px;
}

.about__container {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10vh;
}

.about__header {
    font-size: 35px;
    font-weight: 600;
}

.about__desc {
    font-size: 30px;
    line-height: 125%;
}

.about__right {
    width: 50%;
}

.about__list {
    font-size: 25px;
    line-height: 150%;
    width: 100%;
}

.about__list_item {
    list-style: none;
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.about__list_icon {
    height: 35px;
    width: 35px;
    margin-right: 10px;
}





@media screen and (max-width: 1020px) {
    .about__container {
        width: 90%;
        margin-top: 0vh;
    }

    .about {
       padding-top: 30px;
    }
}

@media screen and (max-width: 890px) {
    .about__header {
        font-size: 25px;
    }
    
    .about__desc {
        font-size: 22px;
    }

    .about__right {
        width: 70%;
    }
}

@media screen and (max-width: 800px) {
    .about__container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .about__left {
        text-align: center;
        margin-bottom: 30px;
    } 
}

@media screen and (max-width: 545px) {
     .about__list {
        padding-left: 10px;
    }
}

@media screen and (max-width: 486px) {
     .about__list {
        font-size: 16px;
        padding-left: 20px;
    }

    .about__header {
        font-size: 22px;
    }

    .about__desc {
        font-size: 20px;
    }
}

@media screen and (max-width: 410px) { 
      .about__list {
        padding-left: 10px;
    }
}
