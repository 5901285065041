.produce {
    width: 100%;
    height: 100vh;
    margin-bottom: 150px;
    /* padding-top: 130px; */
    /* scroll-snap-align: start; */
}

.produce__container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}

.produce__top {
    width: 80%;
    margin-top: 130px;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.produce__title {
    font-size: 30px;
    font-weight: 600;
    line-height: 150%;
    margin-top: 0;
}

.produce__desc {
    font-size: 20px;
    line-height: 150%;

}

.produce__bottom {
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: #1d6937;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    border-top: 3px solid rgb(29, 105, 55, 0.1);
    border-bottom: 3px solid rgb(29, 105, 55, 0.1);
    padding: 20px 0;
    /* margin-top: 30px; */
    margin-bottom: 5px;
}

@media screen and (max-width: 890px) {
    .produce__title {
        font-size: 25px;
    }
    
    .produce__desc {
        font-size: 22px;
    }
}

@media screen and (max-width: 800px) { 
    .produce {
        padding-top: 120px;
        height: 120vh;
    }
    .produce__bottom { 
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .produce__bottom_item {
        margin-bottom: 10px;
    }

    .produce__bottom_item:last-child {
        margin-bottom: 0;
    }

    .produce__title {
        text-align: center;
    }
    
    .produce__desc {
    text-align: center;
    }
 }

 @media screen and (max-width: 700px) { 
    .produce__desc {
        font-size: 18px;
    }
 }