.burger-menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50%;
    background-color: #fff;
    color: #1d6937;
    z-index: 1100;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    /* border-left: 3px solid #1d6937; */
  }

  .burger-menu__close_container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .burger-menu__close {
    width: 30px;
    height: 30px;
    margin-right: 30px;
    margin-bottom: 40px;
  }
  .burger-menu__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 70vh;
  }
  .burger-menu__item {
    padding: 10px;
    padding-left: 20px;
    font-size: 23px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }
  .burger-menu__link {
    cursor: pointer;
    text-decoration: none;
    color: #1d6937;
  }

  .burger-menu__link:hover {
    color: rgb(29, 105, 55, 0.5);

    
  }

  .burger-menu__item-arrow {
    margin-right: 20px;
  }

  @media screen  and (max-width: 610px) {
    .burger-menu {
        width: 100%;
    }
  }
