.intro {
    width: 100%;
    height: 100vh;
    position: relative;
    /* scroll-snap-align: start; */
}

.intro__container {
    visibility:hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
}

.intro__container:first-child {
    opacity: 1;
}

.intro__bg {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100vh;
    object-fit: cover;
}


.intro__title {
    position: absolute;
    left: 10%;
    bottom: 30%;
    color: #fff;
    font-size: 41px;
    font-weight: 600;
    line-height: 140%;
    /* z-index: 9; */
}

@media screen and (max-width: 790px) {
    .intro__title {
        font-size: 35px;
        bottom: 20%;
    }
}

@media screen and (max-width: 630px) {
    .intro__title {
        font-size: 28px;
        bottom: 20%;
        left: 5%;
    }
}