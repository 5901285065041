.examples {
    width: 100%;
    height: 100vh;
    /* margin-bottom: 70px; */
    scroll-snap-align: start;
    padding-top: 100px;
}

.examples__container {
    display: flex;
}

.examples__item {
    width: 33%;
    height: 90vh;
    border-right: 3px solid rgb(29, 105, 55, 0.1);
    border-bottom: 3px solid rgb(29, 105, 55, 0.1);
}

.examples__item:last-child {
    border-right: none;
}

.examples__header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 3px solid rgb(29, 105, 55, 0.1);
    border-bottom: 3px solid rgb(29, 105, 55, 0.1);
}

.examples__img {
    width: 60px;
    height: 60px;
    margin-right: 20px;
}

.examples__title {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
}

.examples__desc {
    /* padding-top: 20px;
    padding-bottom: 20px; */
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.examples__bag {
    width: 300px;
    height: 300px;
}

@media screen and (max-width: 1045px) {
    .examples__title {
        font-size: 20px;
    }

    .examples__img {
        width: 50px;
        height: 50px;
    }
}

@media screen and (max-width: 940px) {
    .examples__title {
        font-size: 17px;
    }

    .examples__img {
        width: 40px;
        height: 40px;
    }

    .examples__bag {
        width: 250px;
        height: 250px;
    }
}


@media screen and (max-width: 800px) { 
    .examples {
        height: 300vh;
    }
    /* .examples {
        margin-top: 30px;
    } */

    .examples__container {
        flex-direction: column;
    }

    .examples__item { 
        width: 100%;
        height: 32%;
    }

    .examples__header {
        border-top: none;
    }

   .examples__header_first {
    border-top: 3px solid rgb(29, 105, 55, 0.1);
   }
}