.header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    background-color: #fff;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid #1d6937;
}

.header__container {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__logo {
    display: flex;
    align-items: center;
}

.header__img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.header__title {
    font-size: 22px;
    font-weight: 600;
}

.header__links {
    display: flex;
    font-size: 18px;
}

.header__link {
    margin-right: 20px;
    cursor: pointer;
}

.active-link {
    font-weight: 600;
}

.header__burger {
    cursor: pointer;
    display: none;
}

.header__burger div {
    width: 20px;
    height: 2px;
    background-color: #1d6937;
    margin-bottom: 2px;
}

@media screen and (max-width: 890px) {
  
    .header__links { 
        display: none;
    }

    .header__container { 
        justify-content: space-between;
        padding: 0 0px;
    }

    .header__burger {
        display: block;
    }
}
