.manufacture {
    width: 100%;
    height: 100vh;
    /* scroll-snap-align: start; */
    padding-top: 20%;
}

.manufacture__container {
    display: flex;
}

.manufacture__header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid rgb(29, 105, 55, 0.1);
    padding-bottom: 15px;
    padding-top: 15px;
}

.manufacture__img {
    width: 60px;
    height: 60px;
    margin-right: 20px;
}

.manufacture__title {
    font-size: 22px;
    font-weight: 600;
}

.manufacture__item {
    width: 33%;
    height: 90vh;
    text-align: center;
    border-right: 3px solid rgb(29, 105, 55, 0.1);
}

.manufacture__item:last-child {
    border-right: none;
}

.manufacture__desc {
    font-size: 18px;
    line-height: 150%;
    padding: 10px 20px;
}

@media screen and (max-width: 910px) {
    .manufacture__title {
        font-size: 20px;
    }
}

@media screen and (max-width: 800px) {
    .manufacture {
        height: 250vh;
    }
   .manufacture__container {
    flex-direction: column;
   }
   .manufacture__item { 
    width: 100%;
    height: 70vh;
   }

   .manufacture__header { 
    border-top: 3px solid rgb(29, 105, 55, 0.1);
    padding: 0;
    margin-top: 20px;
   }

   .manufacture__desc {
    margin-top: 10px;
   }
}
